import React, { useState } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { FoodWorldCupQuery } from "../../graphql-types";

export const pageQuery = graphql`
  query FoodWorldCup {
    chicken: file(relativePath: { eq: "chicken.jpg" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pizza: file(relativePath: { eq: "pizza.jpg" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    taco: file(relativePath: { eq: "taco.jpg" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    salad: file(relativePath: { eq: "taco.jpg" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }

  }
`;

interface FoodProps {
  data: FoodWorldCupQuery;
}

function Food({ data }: FoodProps) {
  const allFoods = [
    {
      fixed: data.chicken.childImageSharp.fixed,
    },
    {
      fixed: data.pizza.childImageSharp.fixed,
    },
    {
      fixed: data.taco.childImageSharp.fixed,
    },
    {
      fixed: data.salad.childImageSharp.fixed,
    },
  ];

  const [remainingFoods, setRemainingFoods] = useState(allFoods);

  const leftFood = remainingFoods[0];
  const rightFood = remainingFoods[1];

  const eliminateRightFood = () => {
    const newRemainingFoods = remainingFoods.filter(
      (_food, index) => index != 1
    );

    setRemainingFoods(newRemainingFoods);
  };

  const eliminateLeftFood = () => {
    const newRemainingFoods = remainingFoods.filter(
      (_food, index) => index != 0
    );

    const temporary = newRemainingFoods[0];
    newRemainingFoods[0] = newRemainingFoods[1];
    newRemainingFoods[1] = temporary;

    setRemainingFoods(newRemainingFoods);
  };

  return (
    <div>
      <h1>Hard to decide what to eat for today?</h1>
      <button onClick={eliminateRightFood}>
        <Img fixed={leftFood.fixed} />
      </button>
      <button onClick={eliminateLeftFood}>
        <Img fixed={rightFood.fixed} />
      </button>
    </div>
  );
}
export default Food;
